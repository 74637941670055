#gallery {
  position: relative;
  width: 100%;
  overflow: hidden;


  .slick-slider-dots {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    .slick-dots {
      margin: 0;
      padding: 0 0 0;
      list-style: none;
      text-align: center;
      height: 13px;
      line-height: 13px;

      li {
        display: inline-block;
        height: 13px;

        button {
          padding: 0;
          margin: 0 4px;
          height: 13px;
          width: 13px;
          display: block;
          display: block;
          border-radius: 50px;
          text-indent: -99999px;
          background: transparent;
          -webkit-appearance: none;
          border: 1px solid $sage;
          box-shadonw: none;
          transition-duration: 0.3s;
          transition-property: all;

          @include media-breakpoint-down(md) {
            height: 10px;
            width: 10px;
          }

          @include media-breakpoint-down(sm) {
            height: 8px;
            width: 8px;
            margin: 0 2px;
          }
        }

        &.slick-active {
          button {
            background: $sage;
          }
        }
      }
    }
  }
}
.carousel {
  &__item {
    padding: 0 20px;
  }

  


  &__controls {
    
  }

  &__btn {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    margin-top: -20px;
    background: $sage;
    border-radius: 100px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: all;

    @include media-breakpoint-down(lg) {
      width: 45px;
      height: 45px;
      background-size: 18px;
    }

    @include media-breakpoint-down(sm) {
      width: 35px;
      height: 35px;
      background-size: 15px;
    }

    &.prev {
      background-image: url(../resources/images/arrow-gallery-left.svg);
      left: 14%;
      margin-right: 10px;

      @include media-breakpoint-down(md) {
        left: 15px;
        margin-right: 0;
      }
    }

    &.next {
      right: 14%;
      margin-left: 10px;
      background-image: url(../resources/images/arrow-gallery.svg);

      @include media-breakpoint-down(md) {
        right: 15px;
        margin-left: 0;
      }
    }

    &:hover,
    &:active {
      background-color: $coral;
    }
  }

  &__caption {
    margin-top: 15px;
    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $dark;
      font-weight: 300;

      font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.3;
    }
  }
}

.slick-slide {
  text-align: center;
  img {
    max-width: 90%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

  }
}
#carousel {
  &__wrapper {
    position: relative;
  }
}