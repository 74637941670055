.cookie-banner {
    position: fixed;
    z-index: 900;
    bottom: 20px;
    max-width: 528px;
    width: 90%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12px);
    padding: 25px;
    border-radius: 4px;
    margin: 0 auto;
    left: 0;
    right: 0;

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }

    p {
        font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1900 - 300)));
        margin: 0;
        padding: 0;
        color: white;


        a {
            color: white;

            &:visited {
                color: white;
            }

            &:hover,
            &:active {
                color: $dark;
                text-decoration: underline;
            }
        }
    }

    button {
        background: $coral;
        border-radius: 5px;
        margin-left: 10px;
        padding: 10px 40px;
        font-weight: 700;
        font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1900 - 300)));
        color: white;
        border: none;
        box-shadow: none;
        outline: 0;
        transition-duration: 0.3s;
        transition-property: all;

        &:hover,
        &:active {
            background: white;
            color: $coral;
        }
    }


    &.hide {
        display: none;
    }
}

