a {
	color:black;

	transition-duration: 0.3s;
    transition-property: all;
	
	&:visited {
		color:black;
	}

	&:hover,
	&:active {
		color:black;

		text-decoration: none;
	}
}

.btn {
	font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;
	font-weight: 700;
	color: white;
	background: $coral;
	padding: 12px 25px;
	border-radius: 10px;

	@include media-breakpoint-down(sm) {
    	padding: 10px 25px;
  	}

	&:visited {
		color: white;
	}

	&:hover,
	&:active {
		background: white;
		color: $coral;
	}

	&--alt {
		background: white;
		color: $coral;

		&:visited {
			color: $coral;
		}

		&:hover,
		&:active {
			background-color: $coral;
			color: white;
		}
	}
}