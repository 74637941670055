.footer {
  position: relative;
  padding-bottom: 100px;

  h2,
  h3,
  p,
  ul,
  li {
    color: white;
  }

  a {
    color: white;

    &:visited {
      color: white;
    }

    &:hover,
    &:active {
      color: white;
      text-decoration: underline;
    }
  }

  &__links {
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
    display: flex;
    justify-content: end;
    line-height: 1.7rem;

    @include media-breakpoint-down(sm) {
      justify-content: start;
    }

    li {
      padding-left: 15px;
      margin-left: 15px;
      font-size: 1.7rem;
      line-height: 1.7rem;

      font-size: calc(12px + (17 - 12) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1;

      position: relative;

      @include media-breakpoint-down(xl) {
        padding-left: 10px;
        margin-left: 10px;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 0px;
        margin-left: 0px;
        padding-right: 10px;
        margin-right: 10px;
      }

      &:before {
        content:'';
        height: 11px;
        width: 1px;
        display: block;
        background: white;
        position: absolute;
        top: 2px;
        left: 0;

        @include media-breakpoint-down(sm) {
          height: 10px;
          left: auto;
          right: 0;
          top: 1px;
        }
      }

      &:first-child {
        &:before {
          content: none;

          @include media-breakpoint-down(sm) {
            content: '';
          }
        }
      }

      &:last-child {
        &:before {
          @include media-breakpoint-down(sm) {
            content: none;
          }
        }
      }
    }

    a {
      font-size: calc(12px + (17 - 12) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1;
      text-decoration: none;
      font-weight: 300;

      &:visited {
        color: white;
      }

      &:hover,
      &:active {
        opacity: 0.5;
        text-decoration: underline;
      }
    }
  }

  &__shapes {
    height: 830px;
    width: 1089px;
    background-image: url(../resources/images/footer-shapes.svg);
    position: absolute;
    left: 0;
    top: -10%;
    background-size: 900px;
    background-repeat: no-repeat;
    background-position: top left;

    @include media-breakpoint-down(xl) {
      width: 750px;
      background-size: 750px;
    }

    @include media-breakpoint-down(sm) {
      width: 500px;
      background-size: 500px;
    }
  }

  &__logo {
    max-width: 178px;

    @include media-breakpoint-down(xl) {
      max-width: 150px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 80px;
      max-width: 130px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 120px;
      margin-top: 60px;
    }
  }

  .agents {
    &__list {
      list-style: none;
      padding: 0;
      margin: 0 0 40px 0;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
      
      li {
        padding-bottom: 6px;
      }

      &:last-child {
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          margin-bottom: 35px;
        }
      }

      a {
        color: white;
        text-decoration: none;

        
        &:visited {
          color: white;
        }

        &:hover,
        &:active {
          opacity: 0.5;
          text-decoration: none;
        }

        &.agent__email {
          text-decoration: underline;

          &:hover,
          &:active {
            text-decoration: underline;
          }
        }
      }
    }

  }

  &__extra {
    text-align: right;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}



#social-icons {
  margin: 0;
  padding: 20px 0 0 0;
  list-style: none;
  display: flex;
  justify-content: end;

  @include media-breakpoint-down(sm) {
    justify-content: start;
  }

  li {
    padding-left: 8px;
  }

  a {
    &:hover,
    &:active {
      opacity: 0.5;
    }

    svg {
      width: 29px;
      height: 29px;

      @include media-breakpoint-down(xl) {
        width: 25px;
        height: 25px;
      }

      @include media-breakpoint-down(sm) {
        width: 25px;
        height: 25px;
      }
    }
  }
}

#siteby {
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1900 - 300)));
  line-height: 1;

  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}