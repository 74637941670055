.about {
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-bottom: 150px;
  }

  h2,
  h3,
  p {
    color: white;
  }

  &__shapes {
    height: 1170px;
    width: 699px;
    background-image: url(../resources/images/about-shapes.svg);
    background-repeat: no-repeat;
    background-size: 699px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-down(xl) {
      width: 550px;
      background-size: 550px;
    }

    @include media-breakpoint-down(sm) {
      width: 400px;
      background-size: 400px;
      top: 0;
      transform: none;
    }
  }

  &__quote {
    position: absolute;
    bottom: 6px;
    transform: translateY(50%);
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0 30px !important;
  }

  .o-pd-top-lg {
    @include media-breakpoint-down(xl) {
      padding-top: 20px;
    }
  }

  &__image {
    position: relative;
    img {
      max-width: 783px;
      width: 94%;

      @include media-breakpoint-down(lg) {
        max-width: 95%
      }

      @include media-breakpoint-down(sm) {
        max-width: 90%;
      }
    }
  }

  &__outline {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      top:20px;
      max-width: 95%;
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }
}