#preload {
  background: $sage;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999999999999999;
  opacity: 1;
  transition: all 1s ease-out 2s;

  &__logo {
    width: 359px;
    height: 170px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s ease-out 1.2s;

    @include media-breakpoint-down(xl) {
      width: 300px;
      height: 142px;
    }

    @include media-breakpoint-down(lg) {
      width: 270px;
      height: 128px;
    }

    @include media-breakpoint-down(md) {
      width: 240px;
      height: 114px;
    }

    @include media-breakpoint-down(sm) {
      width: 210px;
      height: 99px;
    }
  }

  .preload__item {
    position: absolute;

    svg {
      height: auto;
    }
  }

  &__01 {
    top: 0;
    left: 0;

    @include media-breakpoint-down(md) {
      left: -20%;
    }

    svg {
      width: 100%;

      @include media-breakpoint-down(xl) {
        width: 40vw;
      }

      @include media-breakpoint-down(lg) {
        width: 50vw;
      }

      @include media-breakpoint-down(md) {
        width: 60vw;
      }

      @include media-breakpoint-down(sm) {
        width: 70vw;
      }
    }
  }

  &__02 {
    top: 0;
    right: 0;

    @include media-breakpoint-down(md) {
      right: -30%;
    }


    svg {
      width: 100%;

      @include media-breakpoint-down(xl) {
        width: 50vw;
      }

      @include media-breakpoint-down(lg) {
        width: 60vw;
      }

      @include media-breakpoint-down(md) {
        width: 70vw;
      }

      @include media-breakpoint-down(sm) {
        width: 70vw;
      }
    }
  }

  &__03 {
    bottom: 0;
    right: 0;

    svg {
      width: 100%;

      @include media-breakpoint-down(xl) {
        width: 60vw;
      }

      @include media-breakpoint-down(lg) {
        width: 65vw;
      }

      @include media-breakpoint-down(md) {
        width: 70vw;
      }

      @include media-breakpoint-down(sm) {
        width: 80vw;
      }
    }
  }

  &__04 {
    bottom: 0;
    left: 0;

    svg {
      width: 100%;
      
      @include media-breakpoint-down(xl) {
        width: 35vw;
      }

      @include media-breakpoint-down(lg) {
        width: 40vw;
      }

      @include media-breakpoint-down(md) {
        width: 50vw;
      }

      @include media-breakpoint-down(sm) {
        width: 65vw;
      }
    }

    @include media-breakpoint-down(sm) {
      bottom: 10%;
      left: 0;
    }
  }

  .vector-ani-01 {
    opacity: 0;
    transition: all 0.4s ease-out 0.2s;
  }

  .vector-ani-02 {
    opacity: 0;
    transition: all 0.4s ease-out 0.4s;
  }

  .vector-ani-03 {
    opacity: 0;
    transition: all 0.4s ease-out 0.6s;
  }

  .vector-ani-04 {
    opacity: 0;
    transition: all 0.4s ease-out 0.8s;
  }

  .vector-ani-05 {
    opacity: 0;
    transition: all 0.4s ease-out 1s;
  }

  &.is-active {
    .vector-ani-01,
    .vector-ani-02,
    .vector-ani-03,
    .vector-ani-04,
    .vector-ani-05,
    .vector-ani-06 {
      opacity: 1;
    }

    .vector-op-01 {
      opacity: 0.12;
    }

    .vector-op-02 {
      opacity: 0.25;
    }

    .vector-op-03 {
      opacity: 0.32;
    }

    .vector-op-03 {
      opacity: 0.49;
    }

    .vector-op-06 {
      opacity: 0.62;
    }

    #preload__logo {
      opacity: 1;
    }
  }

  &.is-close {
    opacity: 0;
  }
}