/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
@import "links";

#top-wrapper {
	position: fixed;
	background: rgba(104,118,93,0.7);
	top: 0;
	width: 100%;
	padding: 15px 0;
	z-index: 9999;
	backdrop-filter: blur(12px);
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(255,255,255,0.3);


	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		width: 100%;
		flex-direction: row;

		@include media-breakpoint-down(md) {
		   flex-direction: column;
		   margin-top: 15px;
		}

		li {
			flex-grow: 1;
			text-align: center;

			@include media-breakpoint-down(md) {
				padding-bottom: 15px;
			}
		}

		a {
			color: $white;
			font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1900 - 300)));
			line-height: 1;
			text-decoration: none;
			opacity: 1;
			border-bottom: 1px solid transparent;
			display: inline-block;
			padding: 0 0 2px 0;
			letter-spacing: 2px;
			text-transform: uppercase;

			&:visited {
				color: white;
			}

			&:hover,
			&:active {
				opacity: 0.5;

				@include media-breakpoint-down(sm) {
					opacity: 1;
					border-color: white;
				}
			}

			&.active {
				border-color: white;
			}
		}
	}
}

#menu {
&-button {
		outline: 0;
        width: 25px;
        height: 19px;
        position: relative;
        border: none;
        background: none;
        padding: 0;
        margin-left: auto;
        z-index: 30;
        box-shadow: none;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
            outline: 0;
            border: none;
        }

        span {
            display: block;
            position: absolute;
            height: 1px;
            width: 25px;
            background: white;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 9px;
            }

            &:nth-child(4) {
                top: 18px;
            }
        }

        &[aria-expanded="true"]{
            top: auto;
            bottom: 0;

            span {
                &:nth-child(1) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}


/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
@import "footer";