
.f-popup {
  background: $stone;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: none;
  overflow-x: scroll;
  overflow-x: hidden;

  &__inner {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    padding: 80px 24px;
  }

  &__copy {
    h3 {
      margin-bottom: 10px;
    }

    p {
      font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.3;
    }
  }
}

.close {
  position: absolute;
  right: 15px;
  top: 40px;
  width: 20px;
  height: 20px;
  opacity: 1;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 20px;
  width: 1px;
  background-color: $dark;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
} 

.data-table {
  list-style: none;
  border: 1px dashed $dark;
  border-style: dashed;
  margin: 0 auto 30px;
  padding: 0;

  li {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1900 - 300)));
    display: flex;

    span {
      padding: 10px;
      border-bottom: 1px dashed $dark;

      &:first-child {
        flex-grow: 1;
      }
      &:last-child {
        width: 25%;
        flex-shrink: 0;
        border-left: 1px dashed $dark;
      }
    }

    &:last-child {
      span {
        border-bottom: none;
      }
    }
  }

  &__header {

  }
}