#schedule {
  position: relative;

  .container {
    position: relative;
  }

  &__shapes {
    content:'';
    position: absolute;
    top: -15%;
    left: -5%;
    background-image: url(../resources/images/schedule-shapes.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 850px;
    height: 660px;
    background-size: 850px;

    @include media-breakpoint-down(xl) {
      width: 650px;
      height: 540px;
      background-size: 650px;
      top: -10%;
    }

    @include media-breakpoint-down(lg) {
      width: 500px;
      height: 540px;
      background-size: 500px;
      top: -10%;
    }

    @include media-breakpoint-down(sm) {
      width: 340px;
      height: 340px;
      background-size: 340px;
      top: -5%;
    }
  }

  &__table {
    font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1.3;

    margin: 60px 0 0 0;
    padding: 0;
    list-style: none;
    width: 100%;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }

    li {
      display: flex;
      text-align: right;

      span {
        flex: 1;

        &:first-child {
          text-align: left;
        }
      }
    }

    .schedule__item {
      line-height: 40px;
    }
  }

  &__header {
    border-bottom: 1px solid $dark;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 7px;
  }

  &__total {
    font-weight: 700;
    border-top: 1px solid $dark;
    margin-top: 7px;
    padding-top: 20px;
  }

  &__small {
    margin-top: 40px;

    font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1.3;

    span {
      &:first-child {
        margin-right: 5px;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }

  &__image {
    position: relative;
    img {
      max-width: 783px;
      width: 94%;

      @include media-breakpoint-down(lg) {
        max-width: 95%
      }

      @include media-breakpoint-down(sm) {
        max-width: 90%;
      }
    }
  }

  &__outline {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      top:20px;
      max-width: 95%;
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }
}
