html {
	box-sizing: border-box;
	min-height:100%;
  height:100%;
  font-size:62.5%;
	line-height:62.5%;
	margin:0;
  padding:0;
}

*,
*:before,
*:after { 
	box-sizing: inherit;
}

@include scrollbars(.5em, slategray);

body {
	background: $stone;
	min-height: 100%;
	height: 100%;
	font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;
	font-family: $main;
	font-weight: 500;
	font-style: normal;
	color: $dark;
	margin: 0;
	padding: 0;
	position: relative;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	overflow: hidden;

	&.loaded {
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&.no-scroll {
		overflow: hidden;
	}
}

::selection {
  background: $coral;
    color: white;
}
::-moz-selection {
  background: $coral;
    color: white;
}

#page {

	@include media-breakpoint-down(sm) {
    width: 100vw;
		overflow-x: hidden;
		overflow-y: visible;
  }
}

hr {

	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

img {
	height: auto; 
	max-width: 100%; 
	width:100%;
}

ol {
	padding-bottom:30px;
}

figure {
	margin:0;
	padding:0;
}


.o-unlist {
	margin:0;
	padding:0;
	list-style:none;
}


.o-embed-res { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}
}


.container-offset {
	max-width: 100%;
	padding-left: calc((100% - 1440px) / 2);
	position: relative;
	z-index: 5;

	@include media-breakpoint-down(xl) {
    width: 100%;
    padding-left: 24px;
    padding-right: 0;
  }

	@include media-breakpoint-down(sm) {
    width: 100%;
    padding-left: 24px;
    padding-right: 0;
  }

	&--alt {
		padding-left: 0;
		padding-right: calc((100% - 1440px) / 2);

		@include media-breakpoint-down(xl) {
    width: 100%;
	    padding-right: 24px;
	    padding-left: 0;
	  }

		@include media-breakpoint-down(sm) {
    	width: 100%;
	    padding-left: 0;
	    padding-right: 24px;
	  }
	}
}

.col-offset {
  padding-right: 48px !important;

  &--alt {
  	padding-left: 48px !important;
  }
}

.o-bg-stone {
	background: $stone;
}

.o-bg-sage {
	background: $sage;
}

.o-bg-coral {
	background: $coral;
}

//Padding
.o-pd-top-ss {
	padding-top:5px;
}

.o-pd-top-xs {
	padding-top:20px;
}

.o-pd-top-sm {
	padding-top:40px;

}

.o-pd-top-md {
	padding-top: 60px;

	@include media-breakpoint-down(md) {
		padding-top:40px;
	}

	@include media-breakpoint-down(sm) {
		padding-top:20px;
	}
}

.o-pd-top-lg {
	padding-top:150px;

	@include media-breakpoint-down(md) {
		padding-top:100px;
	}

	@include media-breakpoint-down(sm) {
		padding-top:60px;
	}
}

.o-pd-top-xl {
	padding-top:200px;

	@include media-breakpoint-down(xl) {
		padding-top:150px;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 100px;
	}
}

.o-pd-btm-ss {
	padding-bottom:5px;
}

.o-pd-btm-xs {
	padding-bottom:20px;
}

.o-pd-btm-sm {
	padding-bottom:40px;

}

.o-pd-btm-md {
	padding-bottom: 60px;

	@include media-breakpoint-down(md) {
		padding-bottom:40px;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom:20px;
	}
}

.o-pd-btm-lg {
	padding-bottom: 150px;

	@include media-breakpoint-down(md) {
		padding-bottom:100px;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom:60px;
	}
}

.o-pd-btm-xl {
	padding-bottom: 200px;

	@include media-breakpoint-down(xl) {
		padding-bottom:150px;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: 100px;
	}
}

.p-l-50 {
	padding-left:50px;
}

.p-r-50 {
	padding-right:50px;
}

//Margin
.o-mg-none {
	margin:0;
}

.o-mg-top-none {
	margin-top:0px;
}

.o-mg-top-ss {
	margin-top:5px;
}

.o-mg-top-xs {
	margin-top:20px;
}

.o-mg-top-sm {
	margin-top:40px;
}

.o-mg-top-md {
	margin-top: 60px;
}

.o-mg-top-lg {
	margin-top:160px;
}


.o-mg-btm-ss {
	margin-bottom:8px;
}

.o-mg-btm-xs {
	margin-bottom:20px;
}

.o-mg-btm-sm {
	margin-bottom:40px;
}

.o-mg-btm-md {
	margin-bottom: 60px;
}

.o-mg-btm-lg {
	margin-bottom:160px;
}

//Animations
.js-fade-in-left {
	opacity: 0;
	transform: translate(-100px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-right {
	opacity: 0;
	transform: translate(20px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-down {
	opacity: 0;
	transform: translate(0px, -20px); 
  	transition: all 0.5s ease-out 0s;
}


.js-fade-in-up {
	opacity: 0;
	transform: translate(0px, 20px); 
  transition: all 0.5s ease-out 0s;
}

.js-fade-in {
	opacity: 0;
	transition: all 0.5s ease-out 0s;
}

.o-delay-lg {
	transition-delay:0.9s;
}

.o-delay-md {
	transition-delay:0.7s;
}

.o-delay-sm {
	transition-delay:0.5s;
}

.o-delay-xs {
	transition-delay:0.3s;
}

.o-delay-ss {
	transition-delay:0.1s;
}


.o-fade-in-up {
  opacity: 1;
  transform: translate(0px, 0px); 
}

.o-fade-in-down {
  opacity: 1;
  transform: translate(0px, 0px); 
}

.o-fade-in {
  opacity: 1;
}

.js-stroke {
	fill:none;
	
	stroke-width:2;
	stroke-miterlimit:10;
	stroke-dasharray: 3080;
	stroke-dashoffset: 100;
	opacity: 1;
	position: relative;
	z-index: 10;

	&--coral {
		stroke: $coral;
	}

	&--sage {
		stroke: $sage;
	}
	
	&--white {
		stroke: white;
	}

	&.o-stroke-reveal {
		animation: dash 3s linear alternate forwards;
		opacity: 1;
	}
}

@keyframes dash {
  from {
    stroke-dashoffset: 2820;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.js-tick {
	path {
		opacity: 0;
		transition-duration: 0.3s;
    transition-property: all;
	}

	&.o-tick {
		path {
			opacity: 1;
		}
	}
}

@for $i from 1 through 90 {
    .js-tick path:nth-child(#{$i}n) {
        transition-delay: #{$i * 0.02}s;
    }
}
