#members {
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 80px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 80px;
  }

  

  h2,
  h3,
  p,
  ul,
  li {
    color: white;
  }

  a {
    color: white;

    &:visited {
      color: white;
    }

    &:hover,
    &:active {
      color: white;
      text-decoration: underline;
    }
  }

  &__shapes {
    height: 1170px;
    width: 699px;
    background-image: url(../resources/images/members-shapes.svg);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-down(xl) {
      width: 450px;
      background-size: 450px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__image {
    position: relative;
    img {
      max-width: 783px;
      width: 94%;

      @include media-breakpoint-down(xl) {
        max-width: 90%;
      }

      @include media-breakpoint-down(lg) {
        max-width: 95%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 90%;
      }
    }
  }

  &__outline {
    position: absolute;
    top: 20px;
    left: -70px;
    max-width: 100%;
    z-index: 10;

    @include media-breakpoint-down(xl) {
        max-width: 95%;
      }

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }



    @include media-breakpoint-down(sm) {
      top:20px;
      max-width: 95%;
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &__list {
    padding-left: 40px;
    max-width: 600px;

    @include media-breakpoint-down(sm) {
      margin: 0;
      padding-bottom: 20px;
    }

    li {
      padding-bottom: 10px;
    }
  }

  #os-logo {
    padding-top: 80px;
    max-width: 285px;
    margin-bottom: 20px;

    @include media-breakpoint-down(xl) {
      padding-top: 40px;
      max-width: 260px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      max-width: 240px;
    }
  }

  &__sticky {
    position: sticky;
    top: 10px;
    align-self: flex-start;
    height: auto;

    @include media-breakpoint-down(md) {
      position: relative;
      top: auto;
      margin-top: 20px;

      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
  }


  .o-pd-top-lg.col-lg-6 {
    @include media-breakpoint-down(md) {
      padding-top: 30px;
    }
    
  }
}