@media (min-width: 1300px) {
	.container {
	    width: 1278px;
	    max-width: 1278px;
	}
}

@media (min-width:1301px) and (max-width:1500px) {
	.container-offset {
		padding-left: calc((100% - 1230px) / 2);
		padding-right: 0;

		&--alt {
			padding-right: calc((100% - 1230px) / 2);
			padding-left: 0;
		}
	}
}

