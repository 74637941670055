#floorplans {
  position: relative;

  &__shapes {
    content:'';
    position: absolute;
    bottom: -18%;
    right: 0;
    background-image: url(../resources/images/floorplan-shapes.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 558px;
    height: 770px;
    background-size: 558px;
    z-index: 1;

    @include media-breakpoint-down(xl) {
      width: 350px;
      height: 770px;
      background-size: 350px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
      visibility: hidden;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .nav-tabs {
    border-bottom: none;
  }
}


.accordion {
  &-item {
    cursor: unset !important;
    background: transparent;
    border: none;

    &:first-of-type  {
      .accordion-button {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
      
    }
  }

  &-header {
    border-bottom: 1px solid $dark;
  }

  &-button {
    background: transparent;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;

    font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1;

    color: $dark;
    font-weight: 500;
    align-items: baseline;
    cursor: pointer;

    
    padding-top: 15px;
    padding-bottom: 15px;

    .title {
      font-size: calc(11px + (15 - 11) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.3;
      letter-spacing: 1px;
      color: $dark;
      text-transform: uppercase;
      font-weight: 700;
      text-align: left;
    }

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
      border: none;
    }

    &:not(.collapsed) {
      background: transparent;
      color: $dark;
      box-shadow: none;

      &:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjI2ODE0IDAuMjI0MjY3QzAuNTI4NTM4IC0wLjA3NDczODcgMS4wMTc4MyAtMC4wNzQ3ODk4IDEuMzE5NiAwLjIyNDMxOEw4LjUwMDMxIDcuMzQwNDhMMTUuNjgxNCAwLjIyNDI2N0MxNS45ODMxIC0wLjA3NDczODcgMTYuNDcyNCAtMC4wNzQ3ODk4IDE2Ljc3NDIgMC4yMjQzMThDMTcuMDc1OSAwLjUyMzM3NSAxNy4wNzU5IDEuMDA4MjEgMTYuNzc0MiAxLjMwNzI2TDkuMDQ2NjggOC45NjQ5MkM4LjkwMTc2IDkuMTA4NTMgOC43MDUyMyA5LjE4OTE5IDguNTAwMzEgOS4xODkxOUM4LjI5NTM4IDkuMTg5MTkgOC4wOTg4IDkuMTA4NDggNy45NTM5NCA4Ljk2NDg3TDAuMjI2ODY2IDEuMzA3MjFDLTAuMDc0OTYwNyAxLjAwODIxIC0wLjA3NDk2MjYgMC41MjMzMjQgMC4yMjY4MTQgMC4yMjQyNjdaIiBmaWxsPSIjMjEyMTIxIi8+Cjwvc3ZnPgo=);
      }
    }

    span {
      align-items: baseline;

      &:first-child,
      &:last-child {
        flex: 1;
      }
    }

    &:after {
      width: 17px;
      height: 10px;
      background-size: 17px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjI2ODE0IDAuMjI0MjY3QzAuNTI4NTM4IC0wLjA3NDczODcgMS4wMTc4MyAtMC4wNzQ3ODk4IDEuMzE5NiAwLjIyNDMxOEw4LjUwMDMxIDcuMzQwNDhMMTUuNjgxNCAwLjIyNDI2N0MxNS45ODMxIC0wLjA3NDczODcgMTYuNDcyNCAtMC4wNzQ3ODk4IDE2Ljc3NDIgMC4yMjQzMThDMTcuMDc1OSAwLjUyMzM3NSAxNy4wNzU5IDEuMDA4MjEgMTYuNzc0MiAxLjMwNzI2TDkuMDQ2NjggOC45NjQ5MkM4LjkwMTc2IDkuMTA4NTMgOC43MDUyMyA5LjE4OTE5IDguNTAwMzEgOS4xODkxOUM4LjI5NTM4IDkuMTg5MTkgOC4wOTg4IDkuMTA4NDggNy45NTM5NCA4Ljk2NDg3TDAuMjI2ODY2IDEuMzA3MjFDLTAuMDc0OTYwNyAxLjAwODIxIC0wLjA3NDk2MjYgMC41MjMzMjQgMC4yMjY4MTQgMC4yMjQyNjdaIiBmaWxsPSIjMjEyMTIxIi8+Cjwvc3ZnPgo=);
      background-position: bottom right;
    }
  }

  &-body {
    padding-top: 22px;

    p {

      font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.6;
      color: $dark;
      font-weight: 300;
      margin-bottom: 15px;
    }

    p + ul {
      margin-top: -5px;
    }

    ul {
      margin-left: 12px;
      font-size: calc(13px + (17 - 13) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.4;
      margin-top: 0;
      padding-top: 0;

      li {
        //padding-left: 10px;
        padding-bottom: 12px;

        @include media-breakpoint-down(sm) {
          padding-bottom: 5px;
        }
      }

      .floorplan__icon {
        width: 24px;
        margin-right: 15px;

        svg {
          width: 100%;
        }
      }

      &.list-icons {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          //line-height:2.2;
        }
      }

    }
  }
}

.tab-pane {
  width: 100%;
}

.floorplan {
  &__content {
    position: relative;
    width: 100%;
    display: block;

    @include media-breakpoint-down(sm) {
      text-align: right;
    }
  }

  &-map {
    padding-top:20px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding-top:60px;
    }
  }
}

.ff-logo {
  position: absolute;
  top: -50px;
  right: 100px;
  width: 120px;
  height: 120px;

  animation: spin 9s linear infinite;

  @include media-breakpoint-down(xl) {
    width: 110px;
    height: 110px;
    top: 0;
  }

  @include media-breakpoint-down(lg) {
    width: 90px;
    height: 90px;
    top: -20px;
    right: 30px;
  }

  @include media-breakpoint-down(md) {
    width: 80px;
    height: 80px;
    top: 30px;
    right: 30px;
  }

  @include media-breakpoint-down(sm) {
    width: 70px;
    height: 70px;
    top: 30px;
    right: 30px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#accordion-three {
  .ff-logo {
    @include media-breakpoint-down(sm) {
      
    }
  }

  .floorplan-map {
    padding-top: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 120px;
    }
  }
}

#accordion-four {
  .floorplan-map {
    @include media-breakpoint-down(sm) {
      padding-top: 20px;
    }
  }
}