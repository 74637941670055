#location {
  position: relative;
  &__times {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__shapes {
    position: absolute;
    top: -60%;
    right:0;
    margin: 0 auto;
    background-image: url(../resources/images/location-shapes.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 492px;
    height: 898px;
    background-size: 492px;

    @include media-breakpoint-down(xl) {
      width: 420px;
      height: 898px;
      background-size: 420px;
    }

    @include media-breakpoint-down(md) {
      opacity: 0;
      display: none;
      visibility: hidden;
    }
  }
}

.location {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    flex-shrink: 0;
    display: block;
    width: 98px;
    height: 98px;

    @include media-breakpoint-down(xl) {
      width: 80px;
      height: 80px;
      background-size: 80px;
    }

    @include media-breakpoint-down(sm) {
      width: 60px;
      height: 60px;
      background-size: 60px;
    }

    svg {
      width: 100%;
      height: auto;
    }


  }

  &__details {
    padding-left: 24px;

    @include media-breakpoint-down(sm) {
      padding-left: 14px;
    }
  }
}

#map {
  position: relative;

  &__shapes {
    position: absolute;
    top: -20%;
    left: -5%;
    background-image: url(../resources/images/map-shapes.svg);
    background-repeat: no-repeat;
    background-position: top left;
    width: 400px;
    height: 930px;
    background-size: 400px;

    @include media-breakpoint-down(xl) {
      width: 300px;
      height: 930px;
      background-size: 300px;
    }


    @include media-breakpoint-down(sm) {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  &Navigation {
    max-width: 340px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-bottom: 30px;
    }

    ol {
      margin:0;
      padding: 0 0 0 30px;
    }

    li {
      cursor: pointer;

      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.3;

      &.selected {
        color: $dark;
        font-weight: 700;
      }
    }

    .accordion-header{
      border-color: $dark;
    }
  }

  #dlMemberHeading {
    button {
      color: #0D8236;
      //color: $dark;
    }
  }

  #dlMember {
    color: #0D8236;
    //color: $dark;
  }

  #restaurantsHeading {
    button {
      color: #662013;
      //color: $dark;
    }
  }

  #restaurants {
    color: #662013;
    //color: $dark;
  }

  #officeHeading {
    button {
      color: #BC7562;
      //color: $dark;
    }
  }

  #officeOccupiers {
    color: #BC7562;
    //color: $dark;
  }

  .accordion-button {
    font-weight: 700;
    padding: 10px 0;

    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1.3;
  }

  .accordion-body {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1.3;
    padding: 15px 0;
  }

}

#map__wrapper {
  position: relative;
  width: 100%;
  //background: green;
  align-self: start; /* NEEDED */

  svg {
    display: block;
    width: 100%;
    
    g.selected {
      circle{
        fill: #fff;
      }
    }
  }

  g[data-map] {
    cursor: pointer;

    &.selected {
      path {
        fill: $dark;
      }
    }
  }
}

#mypopup {
  position: absolute;
  display: none;
  width: 180px;
  padding: 10px 10px 8px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background-color: $dark;
  color: white;
  border-radius: 6px;
  translate: -50% -100%;
  top: 0;
  right: 0;
}

svg {
  .st25 {
    font-family: "p22-underground", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4px;
  }

   .st27 {
    font-family: "p22-underground", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 4px;
  }

  .st23 {
    font-family: "p22-underground", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 5px;
  }


}

#elizabeth__image {
  position: relative;
}