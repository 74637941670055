h1, h2, h3, h4, h5, h6 {
	clear: both;
}

h1 {
	font-weight: 700;
	color: white;
	margin: 0 0 44px 0;

	font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;

	@include media-breakpoint-down(xl) {
    	margin-bottom: 30px;
  	}

	@include media-breakpoint-down(sm) {
    	margin-bottom: 30px;
  	}
}

h2 {

	font-size: calc(24px + (53 - 24) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;
	color: $dark;
	font-weight: 300;
	margin-bottom: 55px;

	@include media-breakpoint-down(lg) {
    	margin-bottom: 30px;
  	}

	@include media-breakpoint-down(sm) {
    	margin-bottom: 20px;
  	}
}

h3 {
	font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;
	color: $dark;
	margin: 0 0 30px 0;
	font-weight: 700;

	@include media-breakpoint-down(sm) {
    	margin-bottom: 20px;
  	}
}

h4 {

	font-size: calc(17px + (50 - 17) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;

	color: white;
	font-weight: 300;
	margin: 0;
	padding: 0;
	text-align: center;
}

