.loaded {
  .hero {
    #logo,
    h1,
    .hero__copy,
    .hero__image {
      transform: translate(0px, 0px);
      opacity: 1;
    }

    .hero__outline {
      svg {
        path {
          animation: dash 2s linear alternate forwards;
        }
      }
    }

  }
}

.hero {
  background: $sage;
  position: relative;
  width: 100vw;
  padding-bottom: 300px;
  overflow-x: hidden;
  padding-top: 100px;

  @include media-breakpoint-down(xl) {
    height: auto;
    padding-bottom: 150px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 120px;
  }


  .btn {
    margin-top: 23px
  }


  h1 {
    opacity: 0;
    transform: translate(0px, 20px); 
    transition: all 0.5s ease-out 0.7s;
  }

  &__copy {
    opacity: 0;
    transform: translate(0px, 20px); 
    transition: all 0.5s ease-out 0.9s;
  }

  p {
    color: white;
    font-weight: 300;
    font-size: 2.3rem;
    line-height: 3rem;

    font-size: calc(14px + (23 - 14) * ((100vw - 300px) / (1900 - 300)));
    line-height: 1.3;
  }

  &__image {
    position: relative;
    text-align: right;
    opacity: 0;
    transition: all 0.5s ease-out 0.2s;

    img {
      max-width: 900px;
      width: 94%;

      @include media-breakpoint-down(sm) {
        max-width: 90%;
      }
    }
  }

  &__outline {
    position: absolute;
    top: 30px;
    right: 0;
    max-width: 100%;
    height: auto;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      top:20px;
      max-width: 95%;
    }

    svg {
      max-width: 100%;
      height: auto;
    }

    path {
      fill:none;
      stroke: $coral;
      stroke-width:2;
      stroke-miterlimit:10;
      stroke-dasharray: 3080;
      stroke-dashoffset: 100;
    }
  }



}

#logo {
  width: 283px;
  margin-bottom: 60px;
  margin-top: 15%;
  opacity: 0;
  transform: translate(0px, 20px); 
  transition: all 0.5s ease-out 0.5s;

  @include media-breakpoint-down(xl) {
    max-width: 240px;
    margin-top: 0px;
    margin-bottom: 60px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 220px;
    margin-top: 0px;
    margin-bottom: 60px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 200px;
    margin-top: 0;
    margin-bottom: 60px;
  }
}