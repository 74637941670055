p {
	color: $dark;	
	margin-bottom: 30px;
	font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.3;
	

	strong {
		font-weight: 700;
	}

	

	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}
